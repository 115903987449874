import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Section, Text, Title, SvgMode } from '../../components/Core'
import Card1IconDark from '../../assets/image/svg/pricingDark.inline.svg'
import Card1IconLight from '../../assets/image/svg/pricingLight.inline.svg'
import Card2IconDark from '../../assets/image/svg/settlementsDark.inline.svg'
import Card2IconLight from '../../assets/image/svg/settlementsLight.inline.svg'
import Card3IconDark from '../../assets/image/svg/portfolioDark.inline.svg'
import Card3IconLight from '../../assets/image/svg/portfolioLight.inline.svg'
import Card4IconDark from '../../assets/image/svg/taDark.inline.svg'
import Card4IconLight from '../../assets/image/svg/taLight.inline.svg'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import FeaturesCard from '../common/FeaturesCard'

const Feature = () => {
  const { t } = useTranslation('landing', { useSuspense: false });
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  return (
    <>
      {/* <!-- Feature section --> */}
      <Section
        bg={userDarkMode ? '#191B20' : 'light'}
        className="position-relative"
      >
        <Container>
          <Row className="mb-5">
            <Col lg="12">
              <Title variant={"highlight"} style={{ color: userDarkMode ? '#FAFAFA' : '#003D8B' }}>
                {t('serious')}
              </Title>
              <Text
                color={userDarkMode ? '#FAFAFA' : 'black'}
                mb={4}
              >
                {t('lsCombines')}
              </Text>
            </Col>
          </Row>
          <Row>
            <FeaturesCard bgColor={userDarkMode ? 'black' : '#F0F0F0'} linkUrl="pricing" title={t('compPricing')}>
              <SvgMode
                  Dark={Card1IconDark}
                  Light={Card1IconLight}
                  isDarkMode={userDarkMode}
                />
            </FeaturesCard>
            <FeaturesCard bgColor={userDarkMode ? 'black' : '#F0F0F0'} linkUrl="platform" title={t('realTime')}>
                <SvgMode
                  Dark={Card2IconDark}
                  Light={Card2IconLight}
                  isDarkMode={userDarkMode}
                />
            </FeaturesCard>
            <FeaturesCard bgColor={userDarkMode ? 'black' : '#F0F0F0'} linkUrl="portfolio-management" title={t('portfolio')}>
                <SvgMode
                  Dark={Card3IconDark}
                  Light={Card3IconLight}
                  isDarkMode={userDarkMode}
                />
            </FeaturesCard>
            <FeaturesCard bgColor={userDarkMode ? 'black' : '#F0F0F0'} linkUrl="platform" title={t('taTools')}>
                <SvgMode
                  Dark={Card4IconDark}
                  Light={Card4IconLight}
                  isDarkMode={userDarkMode}
                />
            </FeaturesCard>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Feature
